body {
  margin: inherit;
}

.App {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  font-family: 'Nunito Sans', Roboto, arial;
  font-size: 14px;
  color: #162731;
}

.copyright {
  background-color: #162731;
  margin: 0px;
  padding: 10px;
  color: #ffffff;
  font-size: smaller;
  flex-shrink: 0;
}

.intro-header {
  margin-top: 10%;
  letter-spacing: 2px;
}

/* Bootstrap container: */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.react-datepicker__day--selected {
  background-color: #f25c28!important;
}
.react-datepicker__header {
  background-color: #6d7085!important;
}
.react-datepicker__current-month, 
.react-datepicker-time__header, 
.react-datepicker-year-header, 
.react-datepicker__day-name{
  color: white!important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #6d7085!important;
}